import React, { Suspense, lazy } from "react";

import { Box } from "@mui/material";
import { client } from "./config/apollo";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Loading from "./components/loading";

const Consent = lazy(() => import("./pages/consent"));
const Badge = lazy(() => import("./pages/badge"));
const FormBadge = lazy(() => import("./pages/form/badge"));

export default function Root(props) {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="*" element={<></>} />
            <Route path="/core/consent" element={<Consent />} />
            <Route path="/core/badge" element={<Badge />} />
            <Route path="/core/badge/form" element={<FormBadge />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ApolloProvider>
  );
}
